import { defineComponent, onMounted, ref } from 'vue';
export default defineComponent({
  name: "MainHead",
  setup: function setup() {
    var height = ref();

    var handleScroll = function handleScroll() {
      height.value = window.pageYOffset;
    };

    onMounted(function () {
      window.addEventListener('scroll', handleScroll, true);
    });

    var handleTop = function handleTop() {
      window.scrollTo(0, 0);
    };

    return {
      height: height,
      handleTop: handleTop
    };
  }
});