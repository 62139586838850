import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home/Home.vue"),
  },
  {
    path: "/news",
    name: "News",
    redirect: "/news/all",
    component: () => import("../views/News/newsmain.vue"),
    children: [
      {
        path: "/news/item",
        name: "newsitem",
        component: () => import("../views/News/newsid.vue")
      },
      {
        path: "/news/all",
        name: "newsall",
        component: () => import("../views/News/News.vue")
      },
    ]
  },
  {
    path: "/product",
    name: "Product",
    redirect: "/product/detail",
    component: () => import("../views/product/product.vue"),
    children: [
      {
        path: "/product/detail",
        name: "productDetail",
        component: () => import("../views/product/productDetail.vue")
      },
      {
        path: "/product/info",
        name: "/productInfo",
        component: () => import("../views/product/software.vue")
      },
      {
        path: "/product/buy",
        name: "productBuy",
        component: () => import("../views/product/buy.vue")
      }
    ]
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
export default router;
