import MainFoot from "./components/Foot/MainFoot.vue";
import MainHead from "./components/Head/MainHead.vue";
import "./assets/normalize.css";
export default {
  name: "App",
  components: {
    MainHead: MainHead,
    MainFoot: MainFoot
  },
  setup: function setup() {
    return {};
  }
};