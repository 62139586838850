import { createApp } from "vue";
import App from "./App.vue";
import register from "./utils/register";
import router from "./route";
import "lib-flexible";
const app = createApp(App);
register(app);

app.use(router);
app.mount("#app");
